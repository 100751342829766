// Backward-compatible style additions to fix Waypoint's lack of header styling
h1 {
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
}

h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h4 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: var(--line-height-base);

  small {
    font-size: 75%;
  }
}
