@use '@synerg/vdl-css-framework/scss/global/cssVars' as *;

// Namespace the styles so we can apply them as needed.
.mdf {
  @import 'font-awesome/scss/_variables.scss';
  @import "font-awesome/scss/_mixins.scss";
  @import "font-awesome/scss/_core.scss";
  @import "font-awesome/scss/_larger.scss";
  @import "font-awesome/scss/_fixed-width.scss";
  @import "font-awesome/scss/_list.scss";
  @import "font-awesome/scss/_bordered-pulled.scss";
  @import "font-awesome/scss/_animated.scss";
  @import "font-awesome/scss/_rotated-flipped.scss";
  @import "font-awesome/scss/_stacked.scss";
  @import "font-awesome/scss/_icons.scss";
  @import "font-awesome/scss/_screen-reader.scss";
  @import 'adp-icon-font/scss/variables.scss';
  @import 'adp-icon-font/scss/icons.scss';
}

@import 'normalize';

.mdf {
  // Make sure we use monospaced numbers everywhere so columns of numbers will align properly
  font-feature-settings: var(--font-feature-settings);

  @import '@synerg/vdl-css-framework/scss/global/html-less-tags';
  @import '@synerg/vdl-css-framework/scss/global/grid';
  @import '@synerg/vdl-css-framework/scss/components/index';
  @import '@synerg/vdl-css-framework/scss/utilities/index-no-tags';
  @import 'text';

  // Provide a minimum height for the busy indicator shown when a view is not ready.
  .mdfBusyIndicatorContainer {
    min-height: 60px;
  }
}

@import '@synerg/vdl-css-framework/scss/global/grid';
@import '@synerg/vdl-css-framework/scss/components/index';
@import '@synerg/vdl-css-framework/scss/utilities/index-no-tags';
@import '@synerg/vdl-css-framework/scss/vendor/html5boilerplate-print';
